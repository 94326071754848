<template>
  <div class="manage-wrap" :class="getSearchCondition ? 'manage-grid' : ''">
    <search-condition v-if="getSearchCondition"></search-condition>
    <order-detail v-if="route.fullPath.includes('detail')"></order-detail>
    <order-list v-else></order-list>
  </div>
</template>
<script>
import { defineAsyncComponent, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useGetters, useMutations, useActions } from '@/utils/mapStore';
export default {
  name: 'manage-order',
  components: {
    OrderList: defineAsyncComponent(() => import('@/components/order/OrderList.vue')),
    SearchCondition: defineAsyncComponent(() => import('@/components/order/SearchCondition.vue')),
    OrderDetail: defineAsyncComponent(() => import('@/components/order/OrderDetail.vue')),
  },
  setup() {
    const route = useRoute();
    const getSearchCondition = useGetters('order', ['getSearchCondition']);
    const doGetStoreList = useActions('common', ['doGetStoreList']);
    doGetStoreList();
    const doQueryOptions = useActions('order', ['doQueryOptions']);
    doQueryOptions();
    //離開清空vuex
    const RESET = useMutations('order', ['RESET']);
    watch(
      () => route.path,
      () => {
        if (!route.path.includes('/order/list')) RESET();
      }
    );
    return { route, getSearchCondition };
  },
};
</script>
<style lang="scss" scoped>
.manage-wrap {
  width: 100%;
  height: 100%;
}
.manage-grid {
  display: grid;
  grid-template-rows: 180px auto;
  grid-gap: 16px;
}
</style>
